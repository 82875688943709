<template>
    <div class="choosePackageClass">
        <section class="centerDetail">
            <div class="titleBorder">
                选择套餐
            </div>
            <!--卡片套餐选择-->
            <div class="packageListClass">
                <div @click="cardClick(data,index)" class="packageItemClass" :class="current===index?'greenBorder':''"
                     v-for="(data,index) in packageList" :key="index+data">
                    <img class="hotImgClass" src="@/assets/images/storeManage/hot.png" alt="">
                    <img v-if="current===index" class="checkedImgClass" src="@/assets/images/storeManage/checked.png"
                         alt="">
                    <div class="padding-num">
                        <div class="margin-bl">
                            $ <span class="priceClass">{{data.price}}</span> /月
                        </div>
                        <div class="margin-b17" v-for="item in data.list" :key="item.id">
                            <span class="verticle-center">
                                <img v-if="item.id==='1'"
                                     src="@/assets/images/storeManage/icon1.png" alt="">
                                <img v-if="item.id==='2'"
                                     src="@/assets/images/storeManage/icon2.png" alt="">
                                <img v-if="item.id==='3'"
                                     src="@/assets/images/storeManage/icon3.png" alt="">
                                <img v-if="item.id==='4'"
                                     src="@/assets/images/storeManage/icon4.png" alt="">
                                <img v-if="item.id==='5'"
                                     src="@/assets/images/storeManage/icon5.png" alt="">
                            </span>
                            <span class="iconName">{{item.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--购买套餐-->
            <div>
                <div class="titleBorder">
                    <span>购买套餐</span>
                    <span class="resetElementClass">
                    <el-tooltip class="item" effect="dark" content="Right Top 提示文字" placement="right-start">
                        <el-button>
                            <i class="el-icon-info"></i>
                        </el-button>
                    </el-tooltip>
                </span>
                </div>
                <div class="taocanText">选择您的套餐时长，一次购买的时长最少为1个月。</div>
                <div class="resetElementClass lengthBorder">
                    <el-radio-group v-model="lengthRadio">
                        <el-radio-button label="1">1个月</el-radio-button>
                        <el-radio-button label="2">3个月</el-radio-button>
                        <el-radio-button label="3">半年</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="payMoney">
                    <span style="font-size:20px;color:#312F2E">支付金额：</span>
                    <span style="font-size:32px;font-weight: bold;color:#312F2E">$198.00</span>
                    <span class="nextStep" @click="enterNextStep()">下一步</span>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                current: '',
                lengthRadio: '1',
                packageList: [
                    {
                        price: '99',
                        list: [
                            {
                                id: '1',
                                name: '平台佣金0.30%'
                            },
                            {
                                id: '2',
                                name: '不限商品数量'
                            },
                            {
                                id: '3',
                                name: '不限域名绑定'
                            },
                            {
                                id: '4',
                                name: '全球加速'
                            },
                            {
                                id: '5',
                                name: '全部功能'
                            },
                        ],
                    },
                    {
                        price: '99',
                        list: [
                            {
                                id: '1',
                                name: '平台佣金0.30%'
                            },
                            {
                                id: '2',
                                name: '不限商品数量'
                            },
                            {
                                id: '3',
                                name: '不限域名绑定'
                            },
                            {
                                id: '4',
                                name: '全球加速'
                            },
                            {
                                id: '5',
                                name: '全部功能'
                            },
                        ],
                    },
                    {
                        price: '99',
                        list: [
                            {
                                id: '1',
                                name: '平台佣金0.30%'
                            },
                            {
                                id: '2',
                                name: '不限商品数量'
                            },
                            {
                                id: '3',
                                name: '不限域名绑定'
                            },
                            {
                                id: '4',
                                name: '全球加速'
                            },
                            {
                                id: '5',
                                name: '全部功能'
                            },
                        ],
                    },
                    {
                        price: '99',
                        list: [
                            {
                                id: '1',
                                name: '平台佣金0.30%'
                            },
                            {
                                id: '2',
                                name: '不限商品数量'
                            },
                            {
                                id: '3',
                                name: '不限域名绑定'
                            },
                            {
                                id: '4',
                                name: '全球加速'
                            },
                            {
                                id: '5',
                                name: '全部功能'
                            },
                        ],
                    },
                ],
            }
        },
        methods: {
            cardClick(data, index) {
                this.current = index
            },
            enterNextStep() {
                this.$router.push({path: 'completePayment'})
            },
        },
    }
</script>
<style lang="scss">
    @import "@/assets/css/element.scss";
</style>
<style scoped lang="scss">
    .choosePackageClass {
        .centerDetail {
            margin-top: 51px
        }
        .titleBorder {
            font-size: 20px;
            font-weight: 500;
            color: #312F2E;
            padding-bottom: 20px;
            margin-bottom: 43px;
            border-bottom: 2px solid #E2D6CB;
        }
        .lengthBorder {
            padding-bottom: 59px;
            border-bottom: 2px solid #E2D6CB;
        }
        .packageListClass {
            display: flex;
            flex-wrap: wrap;
            .padding-num {
                padding: 40px 0 45px 54px;
                .margin-bl {
                    margin-bottom: 26px;
                    padding-left: 36px;
                }
                .margin-b17 {
                    margin-bottom: 17px;
                }
            }
            .verticle-center {
                vertical-align: middle;
                img {
                    margin-right: 15px;
                }
            }
            .taocanText {
                font-size: 20px;
                color: #312F2E;
                margin-bottom: 20px;
            }
            .packageItemClass {
                width: 272px;
                height: 362px;
                background: #FFFFFF;
                box-shadow: 0px 2px 18px -3px rgba(215, 205, 196, 0.7);
                cursor: pointer;
                border-radius: 6px;
                margin-right: 82px;
                margin-bottom: 82px;
                position: relative;
            }
            .greenBorder {
                border: 1px solid #54AB8E;
            }
            .hotImgClass {
                position: absolute;
                left: -10px;
                top: -10px;
            }
            .checkedImgClass {
                position: absolute;
                right: 0;
                bottom: 0;
            }
            .priceClass {
                font-size: 48px;
                font-weight: bold;
                color: #312F2E
            }
            .iconName {
                color: #D7CDC4;
            }
        }
        .payMoney {
            text-align: right;
            margin-top: 60px;
            .nextStep {
                width: 160px;
                height: 52px;
                line-height: 52px;
                text-align: center;
                display: inline-block;
                background: #54AB8E;
                border-radius: 8px;
                font-size: 20px;
                font-weight: 500;
                color: #FFFFFF;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }

</style>